import eventbus from '@app/eventbus'

/**
 * Use this class as a concrete- or a base class for lists of items.
 * Takes care of maintaining list of items, handling archived / actual items, etcetera.
 *
 * Example: this is a full implementation of a company combo using the companies list. 
 *
 *     <template>
 *         <ActionCombo :items="list.company.list" :noClearable="noClearable" :placeholder="placeholder" :skeleton="skeleton" :disabled="disabled" :rules="rules" v-model="dvalue"></ActionCombo>
 *     </template>
 *     
 *     <script setup>
 *     
 *         const props = defineProps({
 *             disabled:    { type: [Boolean] },
 *             rules:       { type: [Array] },
 *             noClearable: { type: [Boolean], default: false },
 *             skeleton:    { type: [Boolean] },
 *             value:       { type: [String, Number] },
 *             placeholder: { type: [String], default: 'Company' },
 *         })
 *         
 *         import ActionCombo from '@controls/combo/ActionCombo'
 *         import list from '@app/list';
 *         import {computed} from 'vue'
 *     
 *         const value = props.value;
 *         const emit = defineEmits(['input'])
 *     
 *         const dvalue = computed({
 *             get() {
 *                 return value
 *             },
 *             set: function(v) {
 *                 emit('input', v)
 *             }	
 *         })
 *     
 *     </script>
 * 
 * 
 * The exposed API's: 
 * 
 * - list:                            all actual items
 * - listActual(id):                  method which returns the actual items and the one with the given id, even if it is archived
 * - one(id):                         get the one with the given id
 * - oneProp(id, name, defaultValue): get the one with the given id
 * - count                            The number of actual items.
 *  
 * Just implements a couple of shared items.
 * 
 * Usage: 
 * import clsList from '@cls/clsList'
 * 
 * class clsList extends clsList {
 * 
 *      constructor() {
 *         super( {
 *            archivedFlag:    "archived_at",
 *            keyField:        "id",
 *            modelName: "company"
 *         })
 *      }
 * 
 *      // return { 
 *      //      local_field1: received_fieldname1, 
 *      //      local_field2: received_fieldname2, 
 *      //      ...
 *      // }
 *      updateableFields() {
 *         return {
 *             rel_name: 'rel_name',
 *             rel_refr: 'reference',
 *         };
 *      }
 * }
 *
 * export default clsList;
 * 
 */

class clsList {

    list = [];
    archivedList = [];
    fieldMap = {};
    modelName = null;

    archivedFlag = "archived_at";
    keyField = "id";

    constructor(config) {
        config = config || {};
        this.modelName = config.modelName || null;

        this.archivedFlag = config.archivedFlag || "archived_at";
        this.keyField = config.keyField || "id";

        this.fieldMap = config.fieldMap ||{};

        this.registerEvents();
    }

    /**
     * Override to manipulate the item before initially adding the item to the list.
     * return false when the item must not be added.
     * 
     * @param {} item 
     */
    beforeAddingItem(item) {
        // By default, no action
    }
    /**
     * Override to manipulate the list before initially adding the item to the list.
     * 
     * @param {*} list 
     */
    beforeAddingList(list) {
        // By default, do nothing
    }

    /**
     * Fill the list with values.
     * @param {} list 
     */
    fill(list) {
        list = list || [];
        this.beforeAddingList(list);
        this.list = [];
        this.archivedList = [];
        var self = this;
        list.forEach( (item) => {

            if (self.beforeAddingItem(item) !== false) {
                if (item[this.archivedFlag]) {
                    self.archivedList.push(item);
                } else {
                    self.list.push(item);
                }
            }
        })
    }

    /**
     * list actual items unless the 
     * 
     * @param {} id 
     */
    listActual(id) {
        // When no id is given, or when no archive is present, simply return the list.
        if (!id || !this.archivedList.length) {
            return this.list
        }
        // When the archived list doesnot contain the id, just return the list.
        if (!this.archivedList.find( (item) => item[this.keyField] == id)) {
            return this.list
        }
        // Return the archived item
        var self = this;
        var result = this.archivedList.filter( (item) => item[self.keyField] == id);
        // and the rest of the list.
        this.list.forEach( (item) => {
            result.push(item);
        })
        return result;
    }

    /**
     * Remove one or more items with the given ids from the lists.
     * ids can either be a singele value or an array with values
     * Example: 
     *  - list.remove(100)
     *  - list.remove([100])
     *  - list.remove([100, 101, 102])
     */
    remove(ids) {
        if (!ids) {
            return;
        }
        if (!ids.pop) {
            ids = [ids];
        }
        var self = this;
        this.list = this.list.filter( (item) => !ids.find( (id) => id == item[self.keyField])); 
        this.archivedList = this.archivedList.filter( (item) => !ids.find( (id) => id == item[self.keyField])); 
    };

    /**
     * Archive one or more items with the given ids
     * ids can either be a singele value or an array with values
     * Example: 
     *  - list.archive(100)
     *  - list.archive([100])
     *  - list.archive([100, 101, 102])
     */
    archive(ids) {
        if (!ids) {
            return;
        }
        if (!Array.isArray(ids)) {
            ids = [ids];
        }
        // For all IDs, remove them from the actual list and move them to the archived list.
        var self = this;
        ids.forEach( (id) => {
            var index = self.list.findIndex( (item) => item[self.keyField] == id);
            if (index >=0) {
                var listItem = self.list.splice(index,1);
                self.archivedList.push(listItem[0]);
            }     
        })
    };
    
    /**
     * unArchive one or more items with the given ids
     * ids can either be a singele value or an array with values
     * Example: 
     *  - list.unArchive(100)
     *  - list.unArchive([100])
     *  - list.unArchive([100, 101, 102])
     */
     unArchive(ids) {
        if (!ids) {
            return;
        }
        if (!Array.isArray(ids)) {
            ids = [ids];
        }
        // For all IDs, remove them from the actual list and move them to the archived list.
        var self = this;
        ids.forEach( (id) => {
            var index = self.archivedList.findIndex( (item) => item[self.keyField] == id);
            if (index >= 0) {
                var listItem = self.archivedList.splice(index,1);
                self.list.push(listItem[0]);
            }     
        })
    };

    /**
     * Get one item from the list
     * @param {} id 
     */
    one(id){
        if (!id) {
            return null;
        }
        var self = this;
        var item = this.list.find( (item) => item[self.keyField] == id);
        if (!item) {
            var item = this.archivedList.find( (item) => item[self.keyField] == id);
        }
        return item;
    }

    /**
     * Get one prop from one item from the list
     * @param {} id 
     */
    oneProp(id, prop, defaultValue){
        if (!id) {
            return defaultValue;
        }
        var self = this;
        var item = this.list.find( (item) => item[self.keyField] == id);
        if (!item) {
            var item = this.archivedList.find( (item) => item[self.keyField] == id);
        }
        if (!item || !item[prop]) {
            return defaultValue;
        }
        return item[prop];
    }

    /**
     * The number of items in the list
     **/    
    get count() {
        return (this.list||[]).length;
    } 

    /**
     * When specified, the given fields are updated on Saved. 
     * Example: 
     *      return {
     *          // local name   // incoming name
     *          'name':         'usr_name',
     *          'birthday':     'birthday'
     *      }
     * @returns 
     */
    updateableFields() {
        return this.fieldMap;
    }

    /**
     * The onSaved Handler. 
     * When an item is saved, and the item is found in our list, this handler is called. 
     * 
     * The default implementaion:
     * - When updateableFields is implemented, uses its result data to sync an edited fieldlist from the received data to the current object.
     * - Otherwise, copy all fields from the received data to the current object. 
     * - When neither of those is to be used, override onSaved and write custom implementation.
     *
     * In most scenario's, you can override the updateableFields method, and the syncing will be done for you. 
     *
     * @param {*} receivedItem 
     * @param {*} ourItem 
     */
    onSaved(item, receivedData) {
        if (!item ||!receivedData) {
            return;
        }
        let syncData = this.updateableFields();
        if (syncData) {            
            for (var localField in syncData) {
                let foreignField = syncData[localField];
                if (foreignField && localField) {
                    item[localField] = receivedData[foreignField];
                }
            }
        }
        else {
            for (var prop in receivedData) {                        
                item[prop] = receivedData[prop];
            }    
        }
    }

    /**
     * the insert handler. 
     * called when an item is saved and was not found in the list. 
     * when 'false' is returned, the item is not inserted. 
     * 
     * Override this method when required and manipulate the parameter object.
     * 
     * @param {*} item 
     * @returns 
     */
    onInsert(item) { 
        // By default, do nothing
    }

    /**
     * Handle the saved event
     * @param {*} data 
     * @returns 
     */
    onEventSaved(data) {
        if (!data || !data.id) {
            console.error("Received save event without data or id");
            return;
        }
        let item = this.one(data[this.keyField]);        
        // When new item,  
        if (!item) {
            if (false !== this.onInsert(data)) {
                this.list.push(data);
            }                        
        } else {
            if (this.syncFromSaved) {
                this.syncFromSaved(item, data);
            }
            this.onSaved(item, data);
        }
    }

    /**
     * Register for events.
     */
    registerEvents() {
        var self = this;
        var modelName = self.modelName;
        if (!modelName) {
            return;
        }

        eventbus.appdata.loaded.on(  (data) => {
            if (data && data.list && data.list[modelName]) {
                self.fill(data.list[modelName]);            
            }
        });
        // We migrate removing and archiving to one action.
        // As we can foresee, when a user removes an item on the client, it is fine to archive it in the lists.
        // As for user point of view, the item is not available anymore. 
        eventbus.model.removed.on(  (entityName, ids) => {
            if (entityName == modelName) {
                self.archive(ids);            
            }
        });
        eventbus.model.archived.on(  (entityName, ids) => {
            if (entityName == modelName) {
                self.archive(ids);            
            }
        });
        eventbus.model.unarchived.on(  (entityName, ids) => {
            if (entityName == modelName) {
                self.unArchive(ids);            
            }
        });
        eventbus.model.saved.on(  (entityName, data) => {
            if (entityName == modelName) {
                self.onEventSaved(data);            
            }
        });
    }
}

export default clsList