<template>
    <v-card>
    <v-container>
        <v-row class="mb-1">
            <v-col class="">
                <div>Text</div>
            </v-col>
            <v-col>
                <div class="colorblock colorblock-text bordered bg-card pa-4">
                    <div class="">Text</div>
                    <div class="">{{textcolor}}</div>
                </div>
            </v-col>
        </v-row>

        <v-row class="mb-1">
            <v-col class="">
                <div>Secondary text</div>
                <div v-html="escape('<div class=\'bordered text-secondary pa-4\'>Text secondary</div>')"/>
            </v-col>
            <v-col>
                <div class="colorblock colorblock-secondarytext bordered text-secondary bg-card pa-4">
                    <div class="">Secondary text</div>
                    <div class="">{{secondarytextcolor}}</div>
                </div>
            </v-col>
        </v-row>

        <v-row class="mb-1">
            <v-col class="">
                <div>Tertiary text</div>
                <div v-html="escape('<div class=\'bordered text-tertiary pa-4\'>Text tertiary</div>')"/>
            </v-col>
            <v-col>
                <div class="colorblock colorblock-tertiarytext bordered text-tertiary bg-card pa-4">
                    <div class="">Tertiary text</div>
                    <div class="">{{tertiarytextcolor}}</div>
                </div>
            </v-col>
        </v-row>

        <v-row class="mb-1">            
            <v-col class="">
                <div v-html="escape('<div class=\'primary pa-4\'>Primary</div>')"/>
                <div v-html="escape('<div class=\'text-primary pa-4\'>text-primary</div>')"/>
            </v-col>
            <v-col>
                <div class="colorblock colorblock-primary primary pa-4">
                    <div class="text-white">Primary</div>
                    <div class="text-white">{{primary}}</div>
                </div>
                <div class="text-primary">text-primary</div>
            </v-col>
        </v-row>
        <v-row class="mb-1">
            <v-col class="">
                <div v-html="escape('<div class=\'success pa-4\'>Success</div>')"/>
                <div v-html="escape('<div class=\'text-success pa-4\'>text-success</div>')"/>
            </v-col>
            <v-col>
                <div class="colorblock colorblock-success success pa-4">
                    <div class="text-white">Success</div>
                    <div class="text-white">{{success}}</div>
                </div>
                <div class="text-success">text-success</div>
            </v-col>
        </v-row>
        <v-row class="mb-1">
            <v-col class="">
                <div v-html="escape('<div class=\'warning pa-4\'>Warning</div>')"/>
                <div v-html="escape('<div class=\'text-warning pa-4\'>text-warning</div>')"/>
            </v-col>
            <v-col>
                <div class="colorblock colorblock-warning warning pa-4">
                    <div class="text-white">Warning</div>
                    <div class="text-white">{{warning}}</div>
                </div>
                <div class="text-warning">text-warning</div>
            </v-col>
        </v-row>

        <v-row class="mb-1">
            <v-col class="">
                <div v-html="escape('<div class=\'error pa-4\'>Error</div>')"/>
                <div v-html="escape('<div class=\'text-error pa-4\'>text-error</div>')"/>
            </v-col>
            <v-col>
                <div class="colorblock colorblock-error error pa-4">
                    <div class="text-white">Error</div>
                    <div class="text-white">{{error}}</div>
                </div>
                <div class="text-error">text-error</div>
            </v-col>
        </v-row>

        <v-row class="mb-1">
            <v-col class="">
                <div>Background</div>
                <div>Dialog Background</div>
            </v-col>
            <v-col>
                <div class="colorblock colorblock-dialog bg color-background pa-4">
                    <div class="">Background</div>
                    <div class="">{{dialog}}</div>
                </div>
            </v-col>
        </v-row>

        <v-row class="mb-1">
            <v-col class="">
                <div>Card background</div>
                <div v-html="escape('<div class=\'bg-card pa-4\'>card background</div>')"/>
            </v-col>
            <v-col>
                <v-card class="colorblock colorblock-cardstandout standout pa-4">
                    <div class="">Card Standout Background</div>
                    <div class="">{{cardstandout}}</div>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-1">
            <v-col class="">
                <div>Border</div>
                <div v-html="escape('<div class=\'bordered pa-4\'>bordered</div>')"/>
            </v-col>
            <v-col>
                <div class="colorblock colorblock-border bordered bg-card pa-4">
                    <div class="">Border</div>
                    <div class="">{{border}}</div>
                </div>
            </v-col>
        </v-row>

    </v-container>
    </v-card>
</template>




<script>

export default {
    name: 'designtypo',
    components: {
    },
    data() {
        return {
            textcolor: "",
            secondarytextcolor: "",
            tertiarytextcolor: "",
            primary:"",
            success:"",
            warning:"",
            error:"",
            dialog:"",
            cardstandout:"",
            border:""
        }
    },
    methods: {
        escape(htmlStr) {
            return htmlStr.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;");        
        },
        enc(x) {
            return encodeURI(x)
        }
    },

    computed: {
    },

    created (){
        var self = this;
        var fnRgb2Hex = function(p) { // p = "rgb(255, 238, 253)"
            var regex = /[^0-9,]/gi;
            var str = p.replace(regex, '')
            var arr = str.split(',')
            var result = "";
            arr.forEach( (x) => {
                var hex = (Number(x)).toString(16);
                var n = hex.length == 1 ? "0" + hex : hex;
                result = result + n 
            })
            return `#${result}`;
        }

        setTimeout( () => {
            console.log('calculating colors...')
            self.textcolor          = fnRgb2Hex(window.getComputedStyle(document.getElementsByClassName('colorblock-text')[0]).color)
            self.secondarytextcolor = fnRgb2Hex(window.getComputedStyle(document.getElementsByClassName('colorblock-secondarytext')[0]).backgroundColor)
            self.tertiarytextcolor  = fnRgb2Hex(window.getComputedStyle(document.getElementsByClassName('colorblock-tertiarytext')[0]).backgroundColor)
            self.primary            = fnRgb2Hex(window.getComputedStyle(document.getElementsByClassName('colorblock-primary')[0]).backgroundColor)
            self.success            = fnRgb2Hex(window.getComputedStyle(document.getElementsByClassName('colorblock-success')[0]).backgroundColor)
            self.warning            = fnRgb2Hex(window.getComputedStyle(document.getElementsByClassName('colorblock-warning')[0]).backgroundColor)
            self.error              = fnRgb2Hex(window.getComputedStyle(document.getElementsByClassName('colorblock-error')[0]).backgroundColor)
            self.dialog             = fnRgb2Hex(window.getComputedStyle(document.getElementsByClassName('colorblock-dialog')[0]).backgroundColor)
            self.cardstandout       = fnRgb2Hex(window.getComputedStyle(document.getElementsByClassName('colorblock-cardstandout')[0]).backgroundColor)
            self.border             = fnRgb2Hex(window.getComputedStyle(document.getElementsByClassName('colorblock-border')[0]).borderColor)
        }, 1000)
    },
}
</script>
<style>
    .colorblock {
        width:300px;
        height:70px;
    }
</style>