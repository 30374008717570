<template>
    <v-card>
    <v-container>
        <v-row class="mb-4">
            <v-col class="">
                <div v-html="escape('body')"/>
            </v-col>
            <v-col>
                <div> Body / Nunito  / SemiBold(600) / 14px </div>
            </v-col>
        </v-row>

        <v-row class="mb-4">
            <v-col class="">
                <div v-html="escape('<h1>title</h1>')"/>
                <div v-html="escape('<div class=\'text-headline1\'>title</div>')"/>
            </v-col>
            <v-col>
                <h1> H1 / Nunito / ExtraBold(800) / 18px </h1>
            </v-col>
        </v-row>

        <v-row class="mb-4">
            <v-col class="">
                <div v-html="escape('<h2>subtitle</h2>')"/>
                <div v-html="escape('<div class=\'text-headline2\'>subtitle</div>')"/>
            </v-col>
            <v-col>
                <h2> H2 / Nunito / SemiBold(800) / 16px </h2>
            </v-col>
        </v-row>

        <v-row class="mb-4">
            <v-col class="">
                <div v-html="escape('<label>label</label>')"/>
                <div v-html="escape('<div class=\'text-label\'>label</div>')"/>
            </v-col>
            <v-col>
                <label> Label / Nunito Sans / SemiBold(600) / 14px </label>
            </v-col>
        </v-row>

        <v-row class="mb-4">
            <v-col class="">
                <div v-html="escape('<caption>caption</caption>')"/>
                <div v-html="escape('<div class=\'text-caption\'>caption</div>')"/>
            </v-col>
            <v-col>
                <div class="text-caption"> Caption / Nunito Sans / SemiBold(600) / 12px </div>
            </v-col>
        </v-row>

        <v-row class="mb-4">
            <v-col class="">
                <div v-html="escape('<div class=\'small-dialog-title\'>Small dialog title</div>')"/>
                <div v-html="escape('<div class=\'text-small-dialog-title\'>Small dialog title</div>')"/>
            </v-col>
            <v-col>
                <div class="text-small-dialog-title"> Small dialog title / Nunito / ExtraBold(800) / 16px </div>
            </v-col>
        </v-row>

    </v-container>
    </v-card>
</template>

<script>

export default {
    name: 'designtypo',
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
        escape(htmlStr) {
            return htmlStr.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;");        
        },
        enc(x) {
            return encodeURI(x)
        }
    },

    computed: {
    },

    created (){
    },
}
</script>
