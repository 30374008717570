<template>    
    <v-skeleton-loader type="opti-input" v-if="compSkeleton"></v-skeleton-loader>
    
    <v-text-field v-else
        class="input-hourminutes text-right"
        :class="compClass"
        @keypress="keyPress"
        @keydown="keyDown"
        @blur="onBlur"
        hide-details 
        :disabled="compDisabled"
        dense 
        outlined
        :rules="rules"
        :placeholder="compPlaceholder"
        :label="label"
        ref="input"
        v-model="dvalue"
        >
    </v-text-field>
</template>

<script>

import {time} from '@lib/date'


//
// Usage:
// import HourMinuteField from '@controls/input/HourMinuteField'
// <HourMinuteField :model="model" v-model="monday"></HourMinuteField>
//
// or
//   <HourMinuteField :value="line.monday"  @input="e => onSet('monday', e)"></HourMinuteField>
//
//
//
// Implementation Note
//   We do not connect the v-model of the text field directly to the model value.
//   That is because here (in the control), we fix invalid input. 
//   Imaging what would happen if we were connected to the model value directly: 
//    - Somebody types an invalid value:  00:88. 
//    - on Blur, the control fixes this and the corrected value (00:59) is passed back
//      to the model in its minutes value (59). 
//    - All fine until now. 
//    - Now the user types 00:88 again. 
//    - The control fixes up again and passes 59 to the model. 
//    - Now we have a problem. 
//      The model value was 59 already, so it does not change. 
//      Because it does not change, the control is not updated, and just displays 00:88.
//   
//   This is the easiest explainable scenario where things go wrong. 
//   For this reason, we use an internal dummy (dvalue), which we use as direct control binding, and 
//   in parallel, we pass corrected values back to the model.  
//

export default {
        name: 'HourMinutesField',
        components: { },
        props: {
            value: {
                type: [String, Number]
            },
            disabled: {
                type: [Boolean]
            },
            label: {
                type: String
            },
            placeholder: {
                type: [String]
            },
            respectClockTimes: {
                default: true
            },
            rules: {
                type: [Array]
            },
            noBorder: {
                type: [Boolean]
            },
            borderHover: {
                type: [Boolean]
            },
            slimFit: {
                type: [Boolean],
                default: true
            },            
            block: {
                type: [Boolean],
                default: false
            },            
            skeleton: false,
            model: {
                type: [Object]
            }
        
        },
        data () {
            return {                            
                dvalue: "",
                pppp: null
            }
        },
        computed: {
            compPlaceholder: function() {
                if (this.label) {
                    return null;
                }
                return this.placeholder;
            },
            compClass: function() {
                var border = this.noBorder ? "no-border" : ""; 
                var slimfit = ((!this.block) && this.slimFit) ? "slim-fit" : ""; 
                var borderHover = this.borderHover ? "border-hover" : ""; 

                return `${border} ${borderHover} ${slimfit}`;
            },
            compSkeleton: function() {
                if (this.skeleton) {
                    return true;
                }
                if (!this.model) {
                    return false;
                }
                return this.model.isDataLoading;
            },
            compDisabled: function() {
                if (this.disabled) {
                    return true;
                }
                if (!this.model) {
                    return false;
                }
                return this.model.disabled;
            },

        },
        methods: {
            // Set the dummy data value
            setValue(str) {
                this.dvalue = str;
            },
            // Handle a change from the control. 
            // This is different than setting a value from the 'real' input data.
            onControlValueChange: function(v) {
                // Input : "1:44"
                // Output: "104"
                var x = this.machineFormat(v);
                // The value might be changed, for example, because the input was cleared of rubbish.
                // Therefore, always propagate back to the control.
                var parsedBack = this.humanFormat(String(x));
                this.setValue(parsedBack);
                this.$emit("input", x);
            },
            // Handle the input data from the model. It is a time in minutes. 
            // Change it to HH:MM format.
            onDataValueChange: function(v) {
                // In 'complex' reactive scenario's, where an amount is from a nested object, and the amount maps to a comuted getter/setter, we get an unwrapped 
                // instance. So, in this case, the getter is accessable from the value property.                 
                if (v && v.value) {
                    v = v.value;
                }
                let representationValue = this.humanFormat(String(v))
                this.setValue(representationValue);
            },

            // Only allow numeric values and colon
            keyPress($event) {
                let keyCode = $event.keyCode ? $event.keyCode : $event.which;
                console.log('keyPress: ', keyCode)

                var valid = (keyCode >= 48 && keyCode <= 57)  // 0 - 9
                            || keyCode == 16                  // shift
                            || keyCode == 58;                  // :/;

                console.log(keyCode, $event)

                if (!valid) {
                    $event.preventDefault();
                }
            },
            keyDown($event) {
                let keyCode = $event.keyCode ? $event.keyCode : $event.which;
                // 38: key up, 40: key down
                if (($event && $event.target && undefined != $event.target.selectionStart) && (keyCode == 38 || keyCode == 40)) {
                    var pos = $event.target.selectionStart;
                    var value = $event.target.value;
                    if (pos >= 0 && pos <= 2) {
                        value = (keyCode == 38) ? time.incHours(value, this.respectClockTimes) : time.decHours(value, this.respectClockTimes);
                    } else {
                        value = (keyCode == 38) ? time.incMinutes(value, this.respectClockTimes) : time.decMinutes(value, this.respectClockTimes);
                    }
                    this.setValue(value);
                    var self = this;
                    self.$nextTick(function () {
                        $event.target.setSelectionRange(pos, pos)
                    })
                    $event.preventDefault();
                    $event.stopPropagation();
                }

            },
            // Convert the internal value to a value which the model uses.
            // Convert 205 to "3:15" 
            humanFormat(value) {
                return time.minutes2hourminutes(value, this.respectClockTimes);
            },
            // Convert a human readable format to the format the models expect
            // Convert 3:15 to 205
            machineFormat(value) {
                return time.hourminutes2minutes(value, this.respectClockTimes);
            },
            // Handle the blur event. Transfer the control value to the model, and if necesary, 
            // change the control value.
            onBlur(evt) {
                this.onControlValueChange(evt.target.value);
            }
        },
        watch: {            
            'value': function(newVal, oldVal) {
                this.onDataValueChange(newVal);
            },
        },
        mounted() {                        
            this.onDataValueChange(this.value);
        },

    }
</script>
<style>

</style>