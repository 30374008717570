// Usage: 
//    import {date, datetime, time} from '@lib/date'
//    console.log(date.fmt.local(myDate));
// 


import moment from 'moment'

// Iso week extensions for Date object.
// To get the ISO week number (1-53) of a Date object, mydate, use mydate.getWeek().
// To get the corresponding four-digit year, use mydate.getWeekYear(). 
//
// Returns the ISO week of the date.
Date.prototype.getWeek = function() {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
};

// Returns the four-digit year corresponding to the ISO week of the date.
Date.prototype.getWeekYear = function() {
    var date = new Date(this.getTime());
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
};


var date = {
    // Is the date null, empty or faulthy
    isNullOrEmpty(dt) {
        if (!dt || dt == "") {
            return true;
        }
        return !moment(dt).isValid();
    },
    // Get the year of the given date (default now)
    year(dt) {
        var d = dt ? new Date(dt) : new Date();
        if (!d || !d.getHours) {
            d = new Date();
        }
        return d.getFullYear();
    },
    // Get the week number from the given date (default now) 
    week(dt) {
        var d = dt ? new Date(dt) : new Date();
        if (!d || !d.getHours) {
            d = new Date();
        }
        return d.getWeek();
    },
    // Returns the four-digit year corresponding to the ISO week of the date.
    weekYear(dt) {
        var d = dt ? new Date(dt) : new Date();
        if (!d || !d.getHours) {
            d = new Date();
        }
        return d.getWeekYear();
    },
    isoYearWeekToDate: function(y, w, offset) {
        if (!offset) {
            offset = 0;
        }      
        var simple = new Date(y, 0, 1 + (w - 1) * 7);
        var dow = simple.getDay();
        var ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        }
        else {
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        if (offset) {
            ISOweekStart.setDate(ISOweekStart.getDate() + offset); 
        }
        return ISOweekStart;
    },
    addDays: function(dt, days) {
        if (!days) {
            return dt;
        }
        var dateTime = new Date(dt);
        dateTime.setDate(dateTime.getDate() + days);
        return dateTime; 
    }, 
    iso: {
        today: (options) => {
            options = options || {};
            var m = moment();
            if (options.addday) {
                m = m.add(options.addday, 'd');
            }
            return m.format("YYYY-MM-DD");
        },        
    },
    fmt: {
        custom: (dt, fmt, nDays, sourceIsUTC) => {
            if (!dt || dt == "") {
                return "";
            }
            var m = moment(dt);
            if (sourceIsUTC) {
                m = moment.utc(dt).local();
            }
            if (nDays) {
                m = m.add(nDays, 'days');
            } 
            return m.format(fmt)
                .replace(/january/gi, "Januari")
                .replace(/february/gi, "Februari")
                .replace(/march/gi, "Maart")
                .replace(/april/gi, "April")
                .replace(/may/gi, "Mei" )
                .replace(/june/gi, "Juni")
                .replace(/july/gi, "Juli")
                .replace(/august/gi, "Augustus")
                .replace(/september/gi, "September")
                .replace(/october/gi, "Oktober")
                .replace(/november/gi, "November")
                .replace(/december/gi, "December");

        },
        local: (dt, nDays, bTime, sourceIsUTC) => {
            return date.fmt.custom(dt, bTime ? 'DD-MM-YYYY HH:mm' : 'DD-MM-YYYY', nDays, sourceIsUTC);
        },
        localLong: (dt, bTime, sourceIsUTC) => {
            return date.fmt.custom(dt, bTime ? 'DD MMMM, YYYY HH:mm' : 'DD MMMM, YYYY', 0, sourceIsUTC);
        },
        iso: (dt, nDays, sourceIsUTC) => {
            return date.fmt.custom(dt, 'YYYY-MM-DD', nDays, sourceIsUTC);            
        },        
    }
};
// Is dtLeft less than dtRight?
date.lt = (dtLeft, dtRight) => {
    if (date.isNullOrEmpty(dtLeft) || date.isNullOrEmpty(dtRight)) {
        return false;
    }
    return moment(dtLeft) < moment(dtRight);
};
// Is dtLeft less than or equal to dtRight?
date.lte = (dtLeft, dtRight) => {
    if (date.isNullOrEmpty(dtLeft) || date.isNullOrEmpty(dtRight)) {
        return false;
    }
    return moment(dtLeft) <= moment(dtRight);
};

date.isInPast= (dt) => {
    if (!dt) {
        return false; // no date --> not in past.
    }
    return date.fmt.iso(dt) < date.iso.today();
};
date.isToday= (dt) => {
    if (!dt) {
        return false; // no date --> not in past.
    }
    return date.fmt.iso(dt) == date.iso.today();
};
date.dayDiff = (dtLeft, dtRight) => {
    if (!dtLeft) {
        return 0;
    }
    if (!dtRight) {
        dtRight = new Date();
    }
    return moment(dtLeft).diff(moment(dtRight), 'days'); 
}
var datetime = {
    iso: {
        now: () => {
            return moment().format();
        }
    },
    fmt: {
        local: (dt, nDays, sourceIsUTC) => {
            return date.fmt.local(dt, nDays, true, sourceIsUTC);
        },
    }

}

var time = {}

//  In: 225, dc      Out: 3:45
//  In: 60, false    Out: 1
//  In: 60, true     Out: 1:00
// 
time.minutes2hourminutes = function(minutes, check24Hours) {
    if (undefined === check24Hours) {
        check24Hours = true;
    }
    var val = Number(minutes);
    if (!val) {
        val = 0;
    }
    if (check24Hours) {
        if (val >= 24 * 60) {
            val = 23*60+59;
        } 
        else if (Number(val) < 0) {    
            val = 0;
        }
    }

    var h = Math.floor(val/60);
    var m = val%60;
    var lenH = Math.max(`${h}`.length, 2);
    var fmtH = ("000000"+h).slice(-1*lenH);
    var v = fmtH+":"+("00"+m).slice(-2);
    return v;
};

// Get the hour minute parts of a string.  
// in: "3:10", out: {h: 3, m: 10}
// in: "33:10", out: {h: 23, m: 10}
// in: "33:10", true, out: {h: 33, m: 10}
// in: "33:100", out: {h: 23, m: 59}
// in: "incorrect", out: {h: 0, m: 0}
// Note: "12:13:14" is considered HH:MM:SS. SS is ignored.
// in: "3", out:  {h: 3, m: 0}
time.getHourminuteParts = function(str, check24Hours) {
    if (undefined === check24Hours) {
        check24Hours = true;
    }
    var result = {
        h: 0, m: 0
    };
    if (!str) {        
        return result;
    }

    var arr = (""+str).split(":");
    if (!arr || !arr.length) {
        return result;
    }

    result.h = Number(arr[0]);
    if (arr.length >= 2) {
        result.m = Number(arr[1]);
    }
    if (!result.h)  {
        result.h = 0;
    }
    if (!result.m) {
        result.m = 0;
    }
    if (result.m > 59) {
        result.m = 59;
    }

    if (check24Hours) {
        if (result.h > 23) {
            result.h = 23;
            result.m = 59;
        }
    }
    return result;
};

// in: "3:10", out: 190
// in: "incorrect", out: null
// Note: "12:13:14" is considered HH:MM:SS. SS is ignored.
// in: "3", out: null
// in: "3", true  out: 180
time.hourminutes2minutes= function(str, check24Hours) {
    if (undefined === check24Hours) {
        check24Hours = true;
    }

    var result = time.getHourminuteParts(str, check24Hours)
    return result.h * 60 + result.m;
};
time.incHours = function(str, check24Hours) {
    var result = time.getHourminuteParts(str, check24Hours)
    result.h += (check24Hours ? (result.h < 23 ? 1 : 0) : 1);
    return time.minutes2hourminutes(result.h * 60 + result.m, check24Hours);
}
time.decHours = function(str, check24Hours) {
    var result = time.getHourminuteParts(str, check24Hours)
    result.h -= (check24Hours ? (result.h > 0 ? 1 : 0) : 1);
    return time.minutes2hourminutes(result.h * 60 + result.m, check24Hours);
}
time.incMinutes = function(str, check24Hours){
    var result = time.getHourminuteParts(str, check24Hours)
    if (result.m == 59) {
        if (check24Hours) {
            if (result.h < 23) {
                result.m = 0;
                result.h +=1;
            }
        } else {
            result.m = 0;
            result.h +=1;
        }
    } else {
        result.m += 1
    }
    return time.minutes2hourminutes(result.h * 60 + result.m, check24Hours);
}
time.decMinutes = function(str, check24Hours){
    var result = time.getHourminuteParts(str, check24Hours)
    if (result.m == 0) {
        if (result.h > 0) {
            result.m = 59;
            result.h -=1;
        }
    } else {
        result.m -= 1
    }

    return time.minutes2hourminutes(result.h * 60 + result.m, check24Hours);
}

time.partOfDay = function() {
    var h = moment().hour();
    return h < 12 ? "m" : h < 18 ? "a" : "e";
};
time.isMorning = function() {
    return time.partOfDay() == "m";
};
time.isAfternoon = function() {
    return time.partOfDay() == "a";
};
time.isEvening = function() {
    return time.partOfDay() == "e";
}    

window._date = date;
export {
    date, 
    datetime,
    time
}
