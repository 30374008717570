<template>

      <div class="ma-4">
        Combo is project specifiek.<br>
        Zie het voorbeeld in <span class="text-primary">@shared/views/optitest/design/combo.vue</span>
      </div>
  
</template>




<script>

export default {
    name: 'testdesign',
    components: {
        // , Combo 
    },
    data() {
        return {
        }
    },
    methods: {
    },

    computed: {
    },

    created (){
    },
}

</script>