/**
 * Export all lists which are used in the application. 
 * Since lists are subscribed to data load events, we need to have all lists available at application start.
 * 
 * An instance of this class is returned by @app/list so that lists can be used as follows: 
 * 
 * import {company} from '@app/list'
 * 
 * var companies = company.list <-- when added in this class.
 *
 * 
 */

import clsList from '@cls/clsList'
import clsListCurrency from '@cls/list/currency'

import fnExpose from '@lib/expose'
import {ref} from 'vue'

const currency      = ref(new clsListCurrency({modelName: "currency"})).value;

fnExpose({currency}, 'list')

export {
    currency      ,
} 