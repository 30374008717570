<template>    
    <v-skeleton-loader type="opti-input" v-if="compSkeleton"></v-skeleton-loader>

    <v-menu v-else
        v-model="open"
        :close-on-content-click="false"        
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                v-on="on"
               :class="compClass"
                :value="fmt"
                dense
                hide-details
                readonly
                outlined
                :placeholder="compPlaceholder"
                :label="label"
                :rules="rules"
                :disabled="compDisabled"
                @keyup.backspace="onClear"
                @keyup.delete="onClear"
            >
            <template v-if="!noBorder" v-slot:append>
                <span class="cursor-pointer" @click="open=!open"> <Icon class="mt-1 mr-2" small type="calendar"></Icon></span>
            </template>

            </v-text-field>
        </template>
        <v-date-picker  first-day-of-week="1" no-title show-week v-model="dvalue" @input="open = false">
            <v-spacer></v-spacer>         
                <v-btn class="text-center" @click="onClear" text small color="primary">Maak veld leeg</v-btn>                
            <v-spacer></v-spacer>
        </v-date-picker>
    </v-menu>
</template>

<script>

// Usage: 
// import DatePicker from '@controls/input/DatePicker'
//
//   <DatePicker v-model="amount"></DatePicker>

import {date} from '@lib/date'
import Icon from '@controls/icons/Icon'

export default {
        name: 'DatePicker',
        components: {Icon},
        props: {
            placeholder: {
                type: String,
                default: "DD-MM-JJJJ"
            },
            label: {
                type: String
            },
            value: {
                type: String                
            },
            disabled: {
                type: [Boolean]
            },
            rules: {
                type: [Array]
            },
            noBorder: {
                type: [Boolean]
            },
            model: {
                type: [Object]
            },

            skeleton: false,
        },
        data () {
            return {                            
                open: false,
            }
        },
        computed: {
            fmt: function() {
                return date.fmt.local(this.dvalue);                
            },
            compPlaceholder: function() {
                if (this.label) {
                    return null;
                }
                return this.placeholder;
            },
            compClass: function() {
                var border = this.noBorder ? "no-border" : ""; 
                return `${border}`;
            },
            dvalue: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }	
            },
            compSkeleton: function() {
                if (this.skeleton) {
                    return true;
                }
                if (!this.model) {
                    return false;
                }
                return this.model.isDataLoading;
            },
            compDisabled: function() {
                if (this.disabled) {
                    return true;
                }
                if (!this.model) {
                    return false;
                }
                return this.model.disabled;
            },

        },
        methods: {
            onClear: function() {
                this.dvalue = null;
                this.open = false;
            },
        },
    }
</script>
<style>

</style>