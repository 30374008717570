//
// A filter to be used by Search screens where search data is to be transferred between 
// search fields to / from datatable data. 
//
// Usage:
//  import createFilter from '@shared/lib/ui/filter'
//

/* Example usage: 

   1) -- Simple filter - easy search, or a simple datatable   
      In this scenario, the filter will contain just one default field: q.
      This is used merely in high level filters, for example, an overview of relations.

        // TEMPLATE PART: 
        <ListView
            ...
            :filter="filter"      
            ...
        </ListView>

        // SCRIPT
        import createFilter from '@shared/lib/ui/filter2'
        ...
        setup() {
            return { 
                filter: createFilter()
            };
        }
        ...


   2) -- Advanced filter for purchase invoices. 
      The 'advanced' field is not reset when the 'reset' function is used.       
      The JSON is generated by a custom function. 

        var filter = createFilter( {
                id_relation: null,
                date_from: null,
                date_to: null,
                payment_status: null,
                type: null,
                amount_from: null,
                amount_to: null,
                inv_number: null,
                accepted: true,
                rejected: false,
                q: "", // easy search
                advanced: {value: false, reset: false},
            }, 
            // toJSON
            function(filter) {

                return (filter.advanced) ?  {
                    id_relation:    filter.id_relation,
                    date_from:      filter.date_from,
                    date_to:        filter.date_to,
                    payment_status: filter.payment_status,
                    type:           filter.type,
                    amount_from:    filter.amount_from,
                    amount_to:      filter.amount_to,
                    inv_number:     filter.inv_number,
                    accepted:       filter.accepted,
                    rejected:       filter.rejected,
                    } : {
                    q: filter.q,
                };
            }
        );
*/


import string from '@lib/string'
import { ref }  from 'vue'

class clsFilter {

    startSearch = false;
    originalConfig = null;
    startExportExcel = false;

    constructor(config, fnCustomToJson) {
        config = config || {q: ""};
        this.originalConfig = config;
        this.fnCustomToJson = fnCustomToJson;

        for (var field in config) {
            var item = config[field];

            if (item && undefined !== item.value) {
                this[field] = item.value;
            } else {
                this[field] = item;
            }
        }
    }

    /**
     * Reset the properties in the filter to the original provided data.
     */
    reset() {
        for (var field in this.originalConfig) {
            var item = this.originalConfig[field];

            if (item && false === item.reset) {
                // no reset
            } else {
                if (item && undefined !== item.value) {
                    this[field] = item.value;
                } else {
                    this[field] = item;
                }
            }
        }
    };

    search() {
        this.startSearch = true;
    };

    exportExcel() {
        this.startExportExcel = true;
    };

    /**
     * Convert the filter to a json object. 
     * Either the custom toJSON method is called or all properties of the filter are returned.
     * @returns json data
     */
    toJSON() {
        if (this.fnCustomToJson) {
            return this.fnCustomToJson(this);
        }
        var result = {};
        for (var prop in this) {

            // Skip methods
            if (typeof this[prop] == "function") {
                continue;
            }
            // Skip our own helpers
            if (string.isInRange(prop, "startSearch", "originalConfig")) {
                continue;
            }
            result[prop] = this[prop];
        }
        return result;
    };
}
 
function createFilter(config, fnJson) {
    let filter= ref(new clsFilter(config, fnJson));
    return filter.value
}


export default createFilter;
