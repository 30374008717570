<template>
    <v-container>

        <ActionButton main primary @click="onValidate">Validate</ActionButton>
        <ActionButton ml main primary @click="onResetValidate">Reset validation</ActionButton>
        <v-divider class="my-4"></v-divider>


        <h1 @click="onClick"> Outside card</h1>
        <v-row class="mb-4">
            <v-col>
                <h2 class="mb-2">TextField</h2> 
                <TextField placeholder="Straatnaam" v-model="input.text.placeholder"/>
            </v-col>
            <v-col>
                <h2 class="mb-2">NumberField integer</h2> 
                <NumberField integer placeholder="Value" v-model="input.integer.placeholder"/>
            </v-col>
            <v-col>
                <h2 class="mb-2">NumberField percentage</h2> 
                <NumberField percentage placeholder="Value" v-model="input.percentage.placeholder"/>
            </v-col>
            <v-col>
                <h2 class="mb-2">NumberField decimal</h2> 
                <NumberField decimal placeholder="Value" v-model="input.decimal.placeholder"/>
            </v-col>
            <v-col>
                <h2 class="mb-2">NumberField amount</h2> 
                <NumberField amount placeholder="Value" v-model="input.amount.placeholder"/>
            </v-col>
            <v-col>
                <h2 class="mb-2">HourMinutesField</h2> 
                <HourMinutesField placeholder="Vanaf" v-model="input.hm.placeholder"/>
            </v-col>
            <v-col>
                <h2 class="mb-2">DatePicker</h2> 
                <DatePicker placeholder="Vanaf" v-model="input.dp.placeholder"/>
            </v-col>
        </v-row>

        <v-card class="standout">
            <v-container>
                <h1> TextField</h1>
                <v-row class="mb-6 mt-0">
                    <v-col>
                        <h2 class="mb-2">Skeleton</h2> 
                        <TextField :skeleton="!false"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Disabled</h2> 
                        <TextField disabled label="Straatnaam" v-model="input.text.disabled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Mandatory</h2> 
                        <v-form ref="form1">
                            <TextField placeholder="Verplicht veld" :rules="rules.mandatory" v-model="input.text.mandatory"/>
                        </v-form>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Default</h2> 
                        <TextField v-model="input.text.default"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Placeholder</h2> 
                        <TextField placeholder="Straatnaam" v-model="input.text.placeholder"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label empty</h2> 
                        <TextField label="Straatnaam" v-model="input.text.labelempty"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label filled</h2> 
                        <TextField label="Straatnaam" v-model="input.text.labelfilled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">No border</h2> 
                        <TextField no-border label="Straatnaam" v-model="input.text.noborder"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Suggestions</h2> 
                        <TextField placeholder="Kies een suggestie" :suggestions="['one', 'two', 'three']" v-model="input.text.suggestion"/>
                    </v-col>
                </v-row>

                <h1> DatePicker</h1>
                <v-row class="mb-6 mt-0">
                    <v-col>
                        <h2 class="mb-2">Skeleton</h2> 
                        <DatePicker :skeleton="!false"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Disabled</h2> 
                        <DatePicker disabled label="Startdatum" v-model="input.dp.disabled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Mandatory</h2> 
                        <v-form ref="form2">
                            <DatePicker  placeholder="Verplicht" :rules="rules.mandatory" v-model="input.dp.mandatory"/>
                        </v-form>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Default</h2> 
                        <DatePicker v-model="input.dp.default"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Placeholder</h2> 
                        <DatePicker placeholder="Vanaf" v-model="input.dp.placeholder"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label empty</h2> 
                        <DatePicker label="Vanaf" v-model="input.dp.labelempty"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label filled</h2> 
                        <DatePicker label="Vanaf" v-model="input.dp.labelfilled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">No border</h2> 
                        <DatePicker no-border label="Vanaf" v-model="input.dp.noborder"/>
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>

                <h1> HourMinuteField </h1>
                <v-row class="mb-6 mt-0">
                    <v-col>
                        <h2 class="mb-2">Skeleton</h2> 
                        <HourMinutesField :skeleton="!false"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Disabled</h2> 
                        <HourMinutesField disabled label="Vanaf" v-model="input.hm.disabled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Mandatory</h2> 
                        <v-form ref="form3">
                            <HourMinutesField  placeholder="Verplicht" :rules="rules.mandatory" v-model="input.hm.mandatory"/>
                        </v-form>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Default</h2> 
                        <HourMinutesField v-model="input.hm.default"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Placeholder</h2> 
                        <HourMinutesField placeholder="Vanaf" v-model="input.hm.placeholder"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label empty</h2> 
                        <HourMinutesField label="Vanaf" v-model="input.hm.labelempty"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label filled</h2> 
                        <HourMinutesField label="Vanaf" v-model="input.hm.labelfilled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">No border</h2> 
                        <HourMinutesField no-border label="Vanaf" v-model="input.hm.noborder"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">slim-fit</h2> 
                        <HourMinutesField slim-fit label="Vanaf" v-model="input.hm.slimfit"/>
                    </v-col>
                </v-row>

                <h1> NumberField amount </h1>
                <v-row class="mb-6 mt-0">
                    <v-col>
                        <h2 class="mb-2">Skeleton</h2> 
                        <NumberField amount :skeleton="!false"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Disabled</h2> 
                        <NumberField amount disabled label="Value" v-model="input.amount.disabled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Mandatory</h2> 
                        <v-form ref="form4">
                            <NumberField amount  placeholder="Verplicht" :rules="rules.mandatory" v-model="input.amount.mandatory"/>
                        </v-form>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Default</h2> 
                        <NumberField amount v-model="input.amount.default"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Placeholder</h2> 
                        <NumberField amount placeholder="Value" v-model="input.amount.placeholder"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label empty</h2> 
                        <NumberField amount label="Value" v-model="input.amount.labelempty"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label filled</h2> 
                        <NumberField amount label="Value" v-model="input.amount.labelfilled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">No border</h2> 
                        <NumberField amount no-border label="Value" v-model="input.amount.noborder"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">No decimals, 0 - 10000</h2> 
                        <NumberField amount min="0" max="10000" decimals="0" label="Value" v-model="input.amount.range"/>
                    </v-col>
                </v-row>

                <h1>NumberField decimal</h1>
                <v-row class="mb-6 mt-0">
                    <v-col>
                        <h2 class="mb-2">Skeleton</h2> 
                        <NumberField decimal :skeleton="!false"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Disabled</h2> 
                        <NumberField decimal disabled label="Value" v-model="input.decimal.disabled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Mandatory</h2> 
                        <v-form ref="form5">
                            <NumberField decimal  placeholder="Verplicht" :rules="rules.mandatory" v-model="input.decimal.mandatory"/>
                        </v-form>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Default</h2> 
                        <NumberField decimal v-model="input.decimal.default"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Placeholder</h2> 
                        <NumberField decimal placeholder="Value" v-model="input.decimal.placeholder"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label empty</h2> 
                        <NumberField decimal label="Value" v-model="input.decimal.labelempty"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label filled</h2> 
                        <NumberField decimal label="Value" v-model="input.decimal.labelfilled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">No border</h2> 
                        <NumberField decimal no-border label="Value" v-model="input.decimal.noborder"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">4 decimals, 0-10000</h2> 
                        <NumberField decimal decimals="4"  min="0" max="10000" label="Value" v-model="input.decimal.range"/>
                    </v-col>
                </v-row>

                <h1>NumberField percentage</h1>
                <v-row class="mb-6 mt-0">
                    <v-col>
                        <h2 class="mb-2">Skeleton</h2> 
                        <NumberField percentage :skeleton="!false"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Disabled</h2> 
                        <NumberField percentage disabled label="Value" v-model="input.percentage.disabled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Mandatory</h2> 
                        <v-form ref="form6">
                            <NumberField percentage  placeholder="Verplicht" :rules="rules.mandatory" v-model="input.percentage.mandatory"/>
                        </v-form>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Default</h2> 
                        <NumberField percentage v-model="input.percentage.default"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Placeholder</h2> 
                        <NumberField percentage placeholder="Value" v-model="input.percentage.placeholder"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label empty</h2> 
                        <NumberField percentage label="Value" v-model="input.percentage.labelempty"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label filled</h2> 
                        <NumberField percentage label="Value" v-model="input.percentage.labelfilled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">No border</h2> 
                        <NumberField percentage no-border label="Value" v-model="input.percentage.noborder"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">4 percentages, 20-60</h2> 
                        <NumberField percentage decimals="4"  min="20" max="60" label="Value" v-model="input.percentage.range"/>
                    </v-col>
                </v-row>

                <h1>NumberField integer</h1>
                <v-row class="mb-6 mt-0">
                    <v-col>
                        <h2 class="mb-2">Skeleton</h2> 
                        <NumberField integer :skeleton="!false"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Disabled</h2> 
                        <NumberField integer disabled label="Value" v-model="input.integer.disabled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Mandatory</h2> 
                        <v-form ref="form7">
                            <NumberField integer  placeholder="Verplicht" :rules="rules.mandatory" v-model="input.integer.mandatory"/>
                        </v-form>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Default</h2> 
                        <NumberField integer v-model="input.integer.default"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Placeholder</h2> 
                        <NumberField integer placeholder="Value" v-model="input.integer.placeholder"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label empty</h2> 
                        <NumberField integer label="Value" v-model="input.integer.labelempty"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Label filled</h2> 
                        <NumberField integer label="Value" v-model="input.integer.labelfilled"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">No border</h2> 
                        <NumberField integer no-border label="Value" v-model="input.integer.noborder"/>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">0-10000</h2> 
                        <NumberField integer  min="0" max="10000" label="Value" v-model="input.integer.range"/>
                    </v-col>
                </v-row>

                </v-container>
            </v-card>
        </v-container>

</template>




<script>

import TextField from '@controls/input/TextField'
import DatePicker from '@controls/input/DatePicker'
import HourMinutesField from '@controls/input/HourMinutesField'
import NumberField from '@controls/input/NumberField'
import eventbus from '@app/eventbus';
import ActionButton from '@controls/buttons/ActionButton'

export default {
    name: 'designinput',
    components: {
        ActionButton,
        TextField, 
        DatePicker,
        HourMinutesField,
        NumberField
    },
    data() {
        return {
            input: {
                text: {
                    default: "",
                    placeholder: "",
                    labelempty: "",
                    labelfilled: "Julianaweg",
                    noborder: "Julianaweg",
                    disabled: "Julianaweg",
                    skeleton: "",
                    suggestion: "",
                    mandatory: ""
                },
                dp: {
                    default: "",
                    placeholder: "",
                    labelempty: "",
                    labelfilled: "2022-10-21",
                    noborder: "2022-11-21",
                    disabled: "2022-12-21",
                    skeleton: "",
                    suggestion: "",
                    mandatory: "",
                    slimfit: ""
                },
                hm: {
                    default: "",
                    placeholder: "",
                    labelempty: "",
                    labelfilled: "07:50",
                    noborder: "09:20",
                    disabled: "22:59",
                    skeleton: "",
                    suggestion: "",
                    mandatory: "",
                    slimfit: ""
                },
                amount: {
                    currency: 'EUR',
                    default: 0,
                    placeholder: 10,
                    labelempty: 20,
                    labelfilled: 1234.56,
                    noborder: 125.15,
                    disabled: 1921.75,
                    skeleton: 0,
                    mandatory: null,
                    range: 3456
                },
                decimal: {
                    default: 0,
                    placeholder: 10,
                    labelempty: 20,
                    labelfilled: 1234.56,
                    noborder: 125.15,
                    disabled: 1921.75,
                    skeleton: 0,
                    mandatory: null,
                    range: 3456
                },
                percentage: {
                    default: 0,
                    placeholder: 10,
                    labelempty: 20,
                    labelfilled: 12.56,
                    noborder: 22.15,
                    disabled: 99.75,
                    skeleton: 0,
                    mandatory: null,
                    range: 50
                },
                integer: {
                    default: 0,
                    placeholder: 10,
                    labelempty: 20,
                    labelfilled: 1234.56,
                    noborder: 125.15,
                    disabled: 1921.75,
                    skeleton: 0,
                    mandatory: null,
                    minmax: 3456
                },

            }, 
            rules: { 
                mandatory: [ v => {
                    return (!!v || v === 0) || "Veld is verplicht" }],
            }          

        }
    },
    methods: {
        onResetValidate: function() {
            [1,2,3,4,5,6,7,8,9].forEach( (n) => {
                var f = `form${n}`
                if (this.$refs[f]) {
                    this.$refs[f].resetValidation();
                }
            });

        },
        onValidate: function() {
            [1,2,3,4,5,6,7,8,9].forEach( (n) => {
                var f = `form${n}`
                if (this.$refs[f]) {
                    this.$refs[f].validate();
                }
            });

        },
        onClick: function() {
            eventbus.snackbar.info({text: "click event triggered"});
            this.$refs.form1.validate();

        }            
    },

    computed: {
    },

    mounted (){
        window.ti = this;
        window.testdata = this.input;
    },
}
</script>
