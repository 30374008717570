<template>
      <Frame>

        <template v-slot:pagemenu>
            <RouteMenuItem no-icon route="optitest.icons.opticon">OptIcons</RouteMenuItem> 
            <RouteMenuItem no-icon route="optitest.icons.types">Icons by type</RouteMenuItem> 
            <RouteMenuItem no-icon route="optitest.design">Design</RouteMenuItem> 
        </template>

        <template v-slot:title>
            <slot name="title"></slot>
        </template>

        <template v-slot:actions v-if="$slots.actions">
            <slot name="actions">

            </slot>
        </template>
        
        <template v-slot:dialogs>
            <slot name="dialogs"></slot>
        </template>

        <template v-slot:content>
            <slot name="content"></slot>            
        </template>

      </Frame>

</template>

<script>

import RouteMenuItem from "@controls/menu/RouteMenuItem.vue";

// @ is an alias to /src
//import Frame from '@shared/ui/main/Frame.vue'
import Frame from '@/ui/main/Frame.vue'

export default {
    name: 'OptiTest',
    components: {
        Frame, RouteMenuItem
    }, 
}
</script>
