<template>    
    
    <v-skeleton-loader type="opti-input" v-if="compSkeleton"></v-skeleton-loader>
    
    <v-input v-else :disabled="compDisabled" :value="value" :rules="rules" hide-details
        :class="{'no-border':noBorder, 'v-input--is-label-active':cIsLabelActive, 'v-input--is-focused':isFocused, 'primary--text':isFocused, 'v-text-field--placeholder':cIsPlaceholder}"
        class="v-opti-input v-input--hide-details v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined"
    >
        <fieldset aria-hidden="true">
            <legend style="width: 0px;">
                <span class="notranslate">​</span>
            </legend>
        </fieldset>
        <div class="v-text-field__slot">
            <label v-if="label"
            :class="{'v-label--active':cIsLabelActive, 'primary--text':isFocused}"
                class="v-label  theme--light" style="left: 0px; right: auto; position: absolute;">{{label}}</label>
            <slot name="prepend-inner"></slot>
            <input :disabled="compDisabled" 
            style="text-align:right;"
            :placeholder="compPlaceholder"                     
            @focus="onFocus"
            @blur="onBlur"
            ref="inputRef"
            type="text" />
            <label class="percentage-sign ml-1" v-if="compSuffix">{{compSuffix}}</label>
        </div>
    </v-input>
</template>

<script>
//
// Note: 
//  v-currency="{currency: {prefix: symbol}, locale: 'nl'}"
//  This what we actually would like to use because all the currency symbols are used. 
//  However, when using this, the negative sign is placed before the currency symbol.
//  We would only be able to fix this by changing the plugin code, but that is a no go for now. 
//  Hope the upgrade to v2 will fix it. 
//
// Usage: 
//  import NumberField from '@controls/input/NumberField'
//
//   <NumberField v-model="amount"></NumberField>
// or
//   <AmountField :value="line.ihp_amount"  @input="e => onSet('ihp_amount', e)"></AmountField>
//
// or
//   <AmountField v-model="invoice.amount" :currency="invoice.currency_code"></AmountField>
// 
// Remark about the code below. 
// For the vue-currency-input component we are still using v1. 
// This is absolutely not ideal since we have to convert local notation to numeric and vice versa. 
// Also, we need to use timeouts in several places to get the behavior right. 
// 
// The plan is to upgrade to v2. 
// Therefore, for now, we live with this. 
// 
import { useCurrencyInput } from 'vue-currency-input'
import {watch} from 'vue'
import string from '@lib/string'
import {currency as list} from '@app/list';

export default {
        name: 'AmountField',
        components: {},
        props: {                        
            value: {
                type: [String, Number]
            },
            amount: {
                type: [Boolean],
                default: false
            },
            decimal: {
                type: [Boolean],
                default: false
            },
            decimals: {
                type: [String, Number],
                default: 2
            },
            percentage: {
                type: [Boolean],
                default: false
            },
            min: {
                type: [String, Number],
                default: undefined
            },
            max: {
                type: [String, Number],
                default: undefined
            },
            disabled: {
                type: [Boolean]
            },
            placeholder: {
                type: [String]
            },
            label: {
                type: [String]
            },
            rules: {
                type: [Array]
            },
            noBorder: {
                type: [Boolean]
            },
            skeleton: {
                type: [Boolean]
            },
            hideEmpty: {
                type: [Boolean]
            },
            currency: {
                type: [String],
                default: 'EUR'
            },
            suffix: {
                type: [String],
                default: null
            },
            model: {
                type: [Object]
            }
        },
        data () {
            return {    
                isFocused: false                        
            }
        },
        methods: {
            onFocus: function() {
                this.isFocused = true;
            },
            onBlur: function() {
                this.isFocused = false;
            },
            clear: function() {
                this.dvalue = null;
            },
            // Set the data value. Method for possible manipulation
            onEnter: function() {
                this.$emit('enter');              
            },
        },
        computed: {
            compSkeleton: function() {
                if (this.skeleton) {
                    return true;
                }
                if (!this.model) {
                    return false;
                }
                return this.model.isDataLoading;
            },
            compDisabled: function() {
                if (this.disabled) {
                    return true;
                }
                if (!this.model) {
                    return false;
                }
                return this.model.disabled;
            },

            compSuffix: function() {
                if (this.suffix) {
                    return this.suffix;
                }
                if (this.percentage) {
                    return '%'
                }
                return null;
            },
            dvalue: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }	
            },
            cIsLabelActive: function() {
                if (!this.label) {
                    return false;
                }
                if (this.dvalue || this.dvalue === 0) {
                    return true;
                }
                if (this.isFocused) {
                    return true;
                }
                return false;
            },
            cIsPlaceholder: function() {
                if (this.placeholder) {
                    return true;
                }
                return false;
            },
            compClass: function() {
                var border = this.noBorder ? "no-border" : ""; 
                return `${border}`;
            },
            // Label overrides the placeholder.
            compPlaceholder: function() {
                if (this.label) {
                    return null;
                }
                return this.placeholder;
            },
            symbol: function() {
                if (!this.amount) {
                    return '';
                }
                var v = list.one(string.coalesce(this.currency, 'EUR'));
                return `${v.symbol}`;
            }
        },        
        
        setup(props) {
            let options = {
                locale: 'nl-NL',
                "currency": props.currency || 'EUR',
                "currencyDisplay": props.amount ? "narrowSymbol" : "hidden",    // "symbol" | 'narrowSymbol'
                "precision": ( (!props.amount && !props.decimal && !props.percentage) || isNaN(Number(props.decimals))) ? 0 : Number(props.decimals),
                "hideCurrencySymbolOnFocus": true,
                "hideGroupingSeparatorOnFocus": true,
                "hideNegligibleDecimalDigitsOnFocus": true,
                "autoDecimalDigits": false,
                "useGrouping": true,
                "accountingSign": false
            }
            let mi = isNaN(Number(props.min)) ? undefined : Number(props.min);
            let ma = isNaN(Number(props.max)) ? undefined : Number(props.max);
            if (props.percentage && (undefined === mi)) {
                mi = 0;
            }
            if (props.percentage && (undefined === ma)) {
                ma = 100;
            }
            if ( (mi || mi === 0) || (ma || ma === 0)) {
                options.valueRange = {min: (mi || mi === 0) ? mi : undefined, max: (ma || ma === 0) ? ma : undefined};
            }
            const { inputRef, setOptions, setValue } = useCurrencyInput(options);

            watch(() => props.value, // Vue 3: props.modelValue
                (value) => {
                    setValue(value)
                }
            )
            // required when dynamically changing currency.
            // Currency is the only property we support to be changed dynamically. No use to switch to/from integer / percentage etc. 
            watch(
                () => props.currency,
                (cur) => {
                    options.currency = cur || 'EUR'
                    setOptions(options)
                }
            )
            return {
                inputRef: inputRef
            }
        }
    }
</script>
<style>

</style>