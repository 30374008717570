<template>    

    <span class="search-field">
        <v-text-field 
            @keyup.enter="onSearch"
            @click:clear="onClear"
            :value="dvalue"
            :disabled="disabled"
            :placeholder="placeholder"
            @input="dvalue = $event"
            outlined
            dense small hide-details
            >
            <template v-slot:append>
                <span class="cursor-pointer mt-1" @click="onSearch"><Icon small type="search"></Icon></span>
            </template>
        </v-text-field>
    </span>
</template>

<script>
//
// Usage: 
//    import SearchField from '@controls/input/SearchField'
//    <SearchField v-model="filter.q" @search="onSearch"></SearchField>
//
import Icon from '@controls/icons/Icon'

export default {
        name: 'SearchFieldx',
        components: {
            Icon
        },
        props: {
            value: {
                type: [String, Number]
            },
            disabled: {
                type: [Boolean]
            },
            placeholder: {
                type: [String]
            },
            label: {
                type: [String]
            },
        },
        methods: {
            onClear: function() {
                            
                this.dvalue = null;
                var self = this;
                setTimeout(function() {
                    self.onSearch();
                },10);
            },
            onSearch: function(evt, args) {
                this.$emit('search');
            },
        },
        computed: {
            dvalue: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }	
            },
        },
    }
</script>

<style>

</style>