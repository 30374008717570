<template>
    <v-container>


        <h1> Outside card</h1>
        <v-row class="mb-4">
            <v-col>
                <h2 class="mb-2">Checkbox</h2> 
                <Checkbox v-model="input.checked"></Checkbox>
            </v-col>
            <v-col>
                <h2 class="mb-2">Switch</h2> 
                <Checkbox switcher v-model="input.checked"></Checkbox>
            </v-col>
            <v-col>
                <h2 class="mb-2">Radio</h2> 
                <Radiogroup v-model="input.radio">
                        <v-row >
                            <v-col>
                                <Radio value="y" label="Yellow"/>
                            </v-col>
                            <v-col>
                                <Radio value="o" label="Orange"/>
                            </v-col>
                        </v-row>                            
                </Radiogroup>

            </v-col>
            <v-col>
            </v-col>
            <v-col>
            </v-col>
        </v-row>

        <v-card class="standout">
            <v-form ref="form1" lazy-validation>
            <v-container>
                <h1> Checkbox</h1>
                <v-row class="mb-6 mt-0">
                    <v-col>
                        <h2 class="mb-2">Disabled</h2> 
                        <Checkbox disabled v-model="input.checked"></Checkbox>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Default</h2> 
                        <Checkbox v-model="input.checked"></Checkbox>
                    </v-col>
                    <v-col>
                    </v-col>
                    <v-col>
                    </v-col>
                    <v-col>
                    </v-col>
                    <v-col>
                    </v-col>
                    <v-col>
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>
                <h1> Checkbox switch</h1>
                <v-row class="mb-6 mt-0">
                    <v-col>
                        <h2 class="mb-2">Disabled</h2> 
                        <Checkbox switcher disabled v-model="input.checked"></Checkbox>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Default</h2> 
                        <Checkbox switcher v-model="input.checked"></Checkbox>
                    </v-col>
                    <v-col>
                    </v-col>
                    <v-col>
                    </v-col>
                    <v-col>
                    </v-col>
                    <v-col>
                    </v-col>
                    <v-col>
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>
                <h1> Radio</h1>
                <v-row class="mb-6 mt-0">
                    <v-col>
                        <h2 class="mb-2">Disabled</h2> 
                            <Radiogroup disabled v-model="input.radio">
                                 <v-row >
                                     <v-col>
                                         <Radio value="y" label="Yellow"/>
                                     </v-col>
                                     <v-col>
                                         <Radio value="o" label="Orange"/>
                                     </v-col>
                                 </v-row>                            
                            </Radiogroup>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Horizontal</h2> 
                            <Radiogroup v-model="input.radio">
                                 <v-row >
                                     <v-col>
                                         <Radio value="y" label="Yellow"/>
                                     </v-col>
                                     <v-col>
                                         <Radio value="o" label="Orange"/>
                                     </v-col>
                                 </v-row>                            
                            </Radiogroup>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Vertical</h2> 
                            <Radiogroup v-model="input.radio">
                                 <v-row >
                                     <v-col>
                                         <Radio value="y" label="Yellow"/>
                                     </v-col>
                                 </v-row>                            
                                 <v-row >
                                     <v-col>
                                         <Radio value="o" label="Orange"/>
                                     </v-col>
                                 </v-row>                            
                            </Radiogroup>
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>

            </v-container>
            </v-form>
        </v-card>
    </v-container>

</template>




<script>

import Checkbox from "@controls/check/Checkbox.vue";
import Radio from "@controls/check/Radio.vue";
import Radiogroup from "@controls/check/Radiogroup.vue";
import ActionButton from '@controls/buttons/ActionButton'

export default {
    name: 'designcheck',
    components: {
        ActionButton,
        Checkbox, 
        Radio,
        Radiogroup
    },
    data() {
        return {
            input: {
                checked: false,
                radio: 'c' 
            }, 
            rules: { 
                mandatory: [ v => {
                    console.log('rule.mandatory. v: ', v)
                    return (!!v || v === 0) || "Veld is verplicht" }],
            }          

        }
    },
    methods: {
        onResetValidate: function() {
            [1,2,3,4,5,6,7,8,9].forEach( (n) => {
                var f = `form${n}`
                if (this.$refs[f]) {
                    this.$refs[f].resetValidation();
                }
            });

        },
        onValidate: function() {
            [1,2,3,4,5,6,7,8,9].forEach( (n) => {
                var f = `form${n}`
                if (this.$refs[f]) {
                    this.$refs[f].validate();
                }
            });

        },
    },

    computed: {
    },

    mounted (){
        window.tic = this;
        window.testcombodata = this.combo;
    },
}
</script>
