<template>    

    <div class="radio-container" style="position:relative;">
        <v-radio :value="value"
            class="checkbox radiobox mt-0 mb-0"
            :label="label"
            :disabled="compDisabled"
        >
            <template v-slot:append>
            <slot name="append"></slot>
            </template>
            <template v-slot:label>
                <slot name="label"></slot>
            </template>

        </v-radio>
            
    </div>
</template>

<script setup>

// See also RadioGroup
// 
// import Radiogroup from "@controls/check/Radiogroup.vue";
// import Radio from "@controls/check/Radio.vue";
// <Radiogroup :model="model" v-model="model.color">
//      v-row>
//          v-col>
//              <Radio :model="model" value="percentage" label="Percentage"/>
//          </v-col>
//          v-col>
//              <Radio :model="model" value="amount" label="Vast bedrag"/>
//          </v-col>
//      </v-row>                            
// </Radiogroup>

    const props = defineProps({
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        skeleton: {
            type: [Boolean]
        },
        value: {
            type: [String, Number]
        },
        model: {
            type: [Object]
        }

    })
    
    import {computed} from 'vue'
    const compSkeleton = computed({
        get() {
            if (props.skeleton) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.isDataLoading;
        }
    });
    const compDisabled = computed({
        get() {
            if (props.disabled) {
                return true;
            }
            if (!props.model) {
                return false;
            }
            return props.model.disabled;
        }
    });

</script>
