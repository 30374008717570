<template>
    <v-container>

        <v-card class="standout">
            <v-container>
                <v-row class="mb-6 mt-0">
                    <v-col>
                        <h2 class="mb-2">SearchField</h2> 
                        <SearchField placeholder="Zoeken" v-model="filter.q" @search="onSearch"></SearchField>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">AlertDialog</h2> 
                        <ActionButton icon="alert" @click="onAlert">Toon</ActionButton>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">ConfirmDialog</h2> 
                        <ActionButton icon="confirm" @click="onConfirm">Toon</ActionButton>
                    </v-col>
                    <v-col>
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>

                </v-container>
            </v-card>
        </v-container>

</template>




<script>

import TextField from '@controls/input/TextField'
import eventbus from '@app/eventbus';
import ActionButton from '@controls/buttons/ActionButton'
import SearchField from '@controls/input/SearchField'
import createFilter from '@lib/ui/filter'

export default {
    name: 'designmisc',
    components: {
        ActionButton,
        TextField, 
        SearchField
    },
    data() {
        return {
            model: {
                search: "",
            }, 
            rules: { 
                mandatory: [ v => {
                    return (!!v || v === 0) || "Veld is verplicht" }],
            }          

        }
    },
    methods: {
        onSearch() {
            this.snack('search: ' + this.filter.q)
        },
        snack: function(msg) {
            eventbus.snackbar.info({text: msg});
        }, 
        onAlert: function() {
            eventbus.dialog.alert({title: "Let op!", body: 'U heeft de werkbon nog niet geopend. Pas na het openen kunt u de werkbon goed- of afkeuren.'})
        },
        onConfirm: function() {
            var self = this;
            eventbus.dialog.confirm.promise({title: "Weet u het zeker?", body: 'Deze actie kunt u niet ongedaan maken.\nWilt u doorgaan?', labelConfirm:'Absoluut!'})
            .then( () => self.snack('Yep!'))
            .catch( () => self.snack('Nope!'))
        }         
    },
    setup(props) {
        var filter = createFilter( { q: '' } );        
        return { 
            filter: filter
        };
    },
    mounted (){
        window.misctestdata = this.model;
    },
}
</script>
