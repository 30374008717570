<template>
    <v-container>
        <h1 @click="onClick"> Outside card</h1>
            <v-container>
                <v-row class="mb-6">
                    <v-col>
                        <h2 class="mb-2">Action</h2> 
                        <ActionButton @click="onClick">Annuleer</ActionButton>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Main Action + icon</h2> 
                        <ActionButton main icon="pdf" @click="onClick">Voorbeeld</ActionButton>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Action + dropdown</h2> 
                        <ActionButton main dropdown>
                        <template v-slot:dropdown>
                                <ActionMenuItem @click="onClick">Lege regel</ActionMenuItem> 
                                <ActionMenuItem @click="onClick">Standaard tekst</ActionMenuItem> 
                        </template>
                        Tekst
                       </ActionButton>
                    </v-col>
                    <v-col>
                        <h2 class="mb-2">Sub Action disabled</h2> 
                        <ActionButton sub icon="save" @click="onClick" disabled>Opslaan</ActionButton>
                    </v-col>
                </v-row>
        </v-container>

    <v-card class="standout">
        <v-container>
            <v-row class="mb-6">
                <v-col>
                    <h2 class="mb-2">Action</h2> 
                    <ActionButton @click="onClick">Annuleer</ActionButton>
                </v-col>
                <v-col>
                    <h2 class="mb-2">Action + icon</h2> 
                    <ActionButton icon="pdf" @click="onClick">Voorbeeld</ActionButton>
                </v-col>
                <v-col>
                    <h2 class="mb-2">Action + dropdown</h2> 
                    <ActionButton dropdown>
                    <template v-slot:dropdown>
                            <ActionMenuItem @click="onClick">Lege regel</ActionMenuItem> 
                            <ActionMenuItem @click="onClick">Standaard tekst</ActionMenuItem> 
                    </template>
                    Tekst
                </ActionButton>
                </v-col>
                <v-col>
                    <h2 class="mb-2">Action disabled</h2> 
                    <ActionButton icon="save" @click="onClick" disabled>Opslaan</ActionButton>
                </v-col>
            </v-row>

            <v-row class="mb-6">
                <v-col>
                    <h2 class="mb-2">Main Action</h2> 
                    <ActionButton main @click="onClick">Annuleer</ActionButton>
                </v-col>
                <v-col>
                    <h2 class="mb-2">Main Action + icon</h2> 
                    <ActionButton main icon="pdf" @click="onClick">Voorbeeld</ActionButton>
                </v-col>
                <v-col>
                    <h2 class="mb-2">Main Action + dropdown</h2> 
                    <ActionButton main dropdown>
                    <template v-slot:dropdown>
                            <ActionMenuItem @click="onClick">Lege regel</ActionMenuItem> 
                            <ActionMenuItem @click="onClick">Standaard tekst</ActionMenuItem> 
                    </template>
                    Tekst
                </ActionButton>
                </v-col>

                <v-col>
                    <h2 class="mb-2">Main Action disabled</h2> 
                    <ActionButton main icon="save" @click="onClick" disabled>Opslaan</ActionButton>
                </v-col>
            </v-row>

            <v-row class="mb-6">
                <v-col>
                    <h2 class="mb-2">Main Action Primary</h2> 
                    <ActionButton main primary @click="onClick">Annuleer</ActionButton>
                </v-col>
                <v-col>
                    <h2 class="mb-2">Main Action Primary + icon</h2> 
                    <ActionButton main primary icon="pdf" @click="onClick">Voorbeeld</ActionButton>
                </v-col>
                <v-col>
                    <h2 class="mb-2">Main Action + dropdown</h2> 
                    <ActionButton main primary dropdown>
                    <template v-slot:dropdown>
                            <ActionMenuItem @click="onClick">Lege regel</ActionMenuItem> 
                            <ActionMenuItem @click="onClick">Standaard tekst</ActionMenuItem> 
                    </template>
                    Tekst
                </ActionButton>
                </v-col>
                <v-col>
                    <h2 class="mb-2">Main Action Primary disabled</h2> 
                    <ActionButton main primary icon="save" @click="onClick" disabled>Opslaan</ActionButton>
                </v-col>
            </v-row>

            <v-row class="mb-6">
                <v-col>
                    <h2 class="mb-2">Sub Action</h2> 
                    <ActionButton sub @click="onClick">Annuleer</ActionButton>
                </v-col>
                <v-col>
                    <h2 class="mb-2">Sub Action + icon</h2> 
                    <ActionButton sub icon="pdf" @click="onClick">Voorbeeld</ActionButton>
                </v-col>
                <v-col>
                    <h2 class="mb-2">Sub Action + dropdown</h2> 
                    <ActionButton sub dropdown>
                    <template v-slot:dropdown>
                            <ActionMenuItem @click="onClick">Lege regel</ActionMenuItem> 
                            <ActionMenuItem @click="onClick">Standaard tekst</ActionMenuItem> 
                    </template>
                    Tekst
                </ActionButton>
                </v-col>
                <v-col>
                    <h2 class="mb-2">Sub Action disabled</h2> 
                    <ActionButton sub icon="save" @click="onClick" disabled>Opslaan</ActionButton>
                </v-col>
            </v-row>

        </v-container>
    </v-card>
    </v-container>
</template>




<script>

import ActionButton from '@controls/buttons/ActionButton'
import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";
import eventbus from '@app/eventbus';

export default {
    name: 'designbuttons',
    components: {
        ActionButton, ActionMenuItem
    },
    data() {
        return {
        }
    },
    methods: {
        onClick: function() {
            eventbus.snackbar.info({text: "click event triggered"});
        }            
    },

    computed: {
    },

    created (){
    },
}
</script>
