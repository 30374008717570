<template> 

    <v-list-item class="cursor-pointer" inactive active-class="none" @click="onClick" :class="{'opacity-50': compDisabled}">
        <v-list-item-action v-if="!noIcon&&!iconRight&&compIcon" class="mr-1">
            <span class="icon"><Icon small class="btn-icon mr-2" :type="compIcon"></Icon></span>
        </v-list-item-action>
        <v-list-item-content >
            <v-list-item-title>
                <slot>{{text}}</slot>
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action >
            <slot name="action">
                <span v-if="count" style="font-size:smaller">{{count}}</span>
                <span v-if="!noIcon&&compIcon&&iconRight" class="icon"><Icon small class="btn-icon mr-2" :type="compIcon"></Icon></span>
            </slot>
        </v-list-item-action>
    </v-list-item>

</template>

<script>

/*

    Usage: 
    
        import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";        
        <ActionMenuItem action="log.error.open" :payload="item"></ActionMenuItem>
        <ActionMenuItem icon="pdf" @click="onOpenPDF(item)">Open PDF</ActionMenuItem>

        
*/
import navigation from '@app/navigation'
import Icon from '@controls/icons/Icon'

export default {
    name: 'MenuItem',
    components: { Icon },
    props: {
        action: {
            type: [String],
            default: null
        },     
        disabled: {
            type: [Boolean],
            default: false
        },
        count: {
            type: [String, Number],
            default: ""
        },
        payload: {
            type: [Object, Array, String, Number],
        },
        icon: {
            type: [String]
        },
        type: {
            type: [String],
            default: ""
        },
        noIcon: {
            type: [Boolean],
            default: false
        },
        iconRight: {
            type: [Boolean],
            default: false
        },
        noExecute: {
            type: [Boolean],
            default: false
        },
    },
    data () {
        return {             
            actionItem: {},
        }
    },

    methods: {
        onClick() {

            if (this.disabled) {
                return; // Do nothing.
            }
            if (!this.action) {
                this.$emit('click')
                return;
            }
            
            if (navigation.executeAction(this.action, this.payload, this.noExecute)) {
                this.$emit('click')
            }
        },
    },
    mounted() {
        if (this.action) {
            
            this.actionItem = navigation.getAction(this.action) || {};
        }
    },
    computed: {
        text: function() {
            return this.actionItem.text || '-';
        },
        compIcon: function() {
            if (this.noIcon) {
                return null;
            }
            let ico = this.icon;
            if (!ico && this.actionItem) {
                ico = this.actionItem.type;
            }
            if (!ico && this.type) {
                ico = this.type;
            }
            return ico;
        },

        compDisabled: function() {
            if (this.disabled) {
                return true;
            }
            if (undefined === this.actionItem.isAuthorized) {
                return false;
            }
            return !this.actionItem.isAuthorized;
        }
    },

}
</script>