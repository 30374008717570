<template>

      <Page>

        <template v-slot:title>
            Visual Design
        </template>
        <template v-slot:content>
            <v-tabs v-model="tab" class="ml-4 mt-4">
                <v-tab>Buttons</v-tab>
                <v-tab>Typography</v-tab>
                <v-tab>Colors</v-tab>
                <v-tab>Input fields</v-tab>
                <v-tab>Check fields</v-tab>
                <v-tab>Comboboxen</v-tab>
                <v-tab>Misc</v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <v-tabs-items v-model="tab" class="pa-4">
                <v-tab-item>
                        <Buttons/>
                </v-tab-item>
                <v-tab-item>
                        <Typo/>
                </v-tab-item>
                <v-tab-item>
                        <Colors/>
                </v-tab-item>
                <v-tab-item>
                        <Input/>
                </v-tab-item>
                <v-tab-item>
                        <Check/>
                </v-tab-item>
                <v-tab-item>
                        <Combo/>
                </v-tab-item>
                <v-tab-item>
                        <Misc/>
                </v-tab-item>
            </v-tabs-items>

        </template>

      </Page>
  
</template>




<script>

import Page from '@/views/optitest/page'

import Buttons from '@shared/views/optitest/design/buttons.vue'
import Typo from '@shared/views/optitest/design/typography.vue'
import Colors from '@shared/views/optitest/design/colors.vue'
import Input from '@shared/views/optitest/design/input.vue'
// import Combo from '@shared/views/optitest/design/combo.vue'
import Combo from './combo.vue'
import Check from '@shared/views/optitest/design/check.vue'
import Misc from '@shared/views/optitest/design/misc.vue'


export default {
    name: 'testdesign',
    components: {
        Page, Buttons, Typo, Colors, Input, Check, Misc, Combo 
    },
    data() {
        return {
            tab: 0
        }
    },
    methods: {
    },

    computed: {
    },

    created (){
    },
}

</script>